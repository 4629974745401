import React from "react"
import ServiceSidebarArchitecture from "./ServiceSidebarArchitecture"
import details1 from "../../assets/images/services/banner-web-development.jpg"

const WebArchitectureContent = ({ location }) => {
  return (
    <section className="services-details-area ptb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="services-details-image">
              <img src={details1} alt="about" />
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <ServiceSidebarArchitecture location={location} />
          </div>

          <div className="col-lg-12 col-md-12">
            <div className="services-details-desc">
              <span className="sub-title">SOFTWARE SOFTWARE DESIGN</span>

              <h3>API Gateway Design</h3>
              <h5>
                API Strategy Design for long term external interfaces accessible
                in internet. Proper documentation and examples preparation.
                Access rights, limit and api monetisation management.
              </h5>

              <p>
                In order to support external clients, often a system would have
                an API released for 3rd party usage. This creates system
                dependencies that may cause various problems either on customer
                client side, or within API server.
              </p>

              <div className="row align-items-center">
                <div className="col-lg-12 col-md-12">
                  <div className="content">
                    <h3>
                      Typical software challenges we observe in projects are:
                    </h3>
                    <ul>
                      <li>
                        No standardized release cycle and versioning for API
                      </li>
                      <li>No backward support for previous versions</li>
                      <li>
                        Inconsistent API standards used across various domains
                        and components
                      </li>
                      <li>No client accelerator libraries provided</li>
                      <li>
                        Lack of flexibility in server services because of too
                        many client dependencies
                      </li>
                      <li>
                        No strategy for API standardisation that would allow
                        targeted customisations for different clients
                      </li>
                      <li>
                        Lack of clear publicly available documentation with
                        examples
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <p>
                API Strategy execution is a long-term endeavour. With proper
                processes and standards your customers, either internal or
                external, will be happy to have stable API and good
                communication regarding potential feature evolution. We can
                prepare a complete API strategy with proper design guidelines
                for developers so that your API architecture will be consistent
                and faster to develop and diagnose.
              </p>

              <h3 className="pt-30">Technology</h3>
              <p>
                Technologies & tools that we typically use in our API Gateway
                solutions.
              </p>
              <ul className="technologies-features">
                <li>
                  <span>Kong/NGINX</span>
                </li>
                <li>
                  <span>KrakenD</span>
                </li>
                <li>
                  <span>Tyk</span>
                </li>
                <li>
                  <span>Glo/Envoy</span>
                </li>
                <li>
                  <span>Apigee</span>
                </li>
                <li>
                  <span>WSO2</span>
                </li>
                <li>
                  <span>Amazon API Gateway</span>
                </li>
                <li>
                  <span>Google Cloud Endpoints</span>
                </li>
                <li>
                  <span>Azure API Gateway</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WebArchitectureContent
